import React, { useCallback, useRef, useState, useContext } from 'react';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import {
  Divider,
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  Tooltip,
  Paper,
  Checkbox,
  Dialog,
  DialogActions
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ChevronLeft } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import { clamp } from 'lodash';
import { HLRContent } from './HLRContent';
import { HlrFormData } from './types';
import { NavLink, useHistory } from 'react-router-dom';
import {
  SaveHighlevelRequirementMutation,
  HighLevelRequirementDetail,
  HlrStatus,
  useDeleteHlrActivityMutation,
  HlrActivityTypeDateRangeInput
} from 'src/graphql/generated';
import { useMemberAttachments } from './Upload/useMemberAttachments';
import { useSubmitHlrUpload } from './Upload/useSubmitHlrUpload';
import { Attachments } from 'src/components/Attachments/Attachments';
import { AttachmentsArray } from './types';
import { Alerts } from './Alerts';
type hlrDetailsKeyProps = {
  hlrMemberCardDetails: HlrActivityTypeDateRangeInput;
  hlrSearchCardDetails: HighLevelRequirementDetail;
};
const HLRForm = ({ hlrMemberCardDetails, hlrSearchCardDetails }: hlrDetailsKeyProps) => {
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;
  const [agreeCheckbox, setAgreeCheckbox] = useState<boolean | undefined>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const history = useHistory();

  const hlrCardDetails = hlrSearchCardDetails;

  const { memberAttachmentsData } = useMemberAttachments({
    hlrCardDetails
  });

  const isEditing = hlrCardDetails?.hlr_status === HlrStatus.HlrSubmitted;
  const { register, handleSubmit, control, watch, setValue, reset, formState } =
    useForm<HlrFormData>({
      defaultValues: {
        eaHours: 0,
        rpHours: 0,
        moHours: 0,
        hours_spent: 0,
        attachments: [],
        agreeToEvidenceProof: false
      },
      mode: 'onChange'
    });

  const scrollRef = useRef<HTMLElement>(null);

  const scrollToTop = useCallback(() => {
    setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }, 10);
  }, []);
  const handleDialog = () => {
    setDialogOpen(false);
  };

  // destructured here because otherwise this does not work on first render
  const { isDirty, isValid } = formState;
  const watchAllFields = watch();
  const nanToZero = (num: number) => {
    if (Number.isNaN(num)) {
      return 0;
    } else return num;
  };
  const roundHalf = (num: number) => {
    return Math.round(num * 2) / 2;
  };
  const roundField = useCallback(
    (fieldName: 'eaHours' | 'rpHours' | 'moHours' | 'hours_spent') => {
      setValue(fieldName, clamp(roundHalf(watch(fieldName)), 0, 500), {
        shouldDirty: true,
        shouldValidate: true
      });
    },
    [setValue, watch]
  );
  const {
    submit,
    data: submissionResponse,
    error: submissionError,
    reset: resetSubmissionMutation
  } = useSubmitHlrUpload({
    onCompleted: (data: SaveHighlevelRequirementMutation) => {
      scrollToTop();
    },
    onError: (e: any) => {
      scrollToTop();
    }
  });

  const submitForm = handleSubmit((data) => {
    submit(data);
  });

  const [deleteHlrActivityMutation, { data: deleteSuccess, error: deleteError }] =
    useDeleteHlrActivityMutation({
      variables: {
        memberid: activeMemberId,
        activitykey: hlrCardDetails?.sortkey
      },
      onCompleted: () => {
        setDialogOpen(false);
        history.push('/mycpd/');
      },
      refetchQueries: ['getMemberHlrDetails', 'getMemberHlrSearch']
    });

  React.useEffect(() => {
    if (isEditing) {
      reset({
        eaHours: hlrCardDetails?.cpd_types?.find((cpdType) => cpdType?.type === 'EA')?.hours || 0,
        rpHours: hlrCardDetails?.cpd_types?.find((cpdType) => cpdType?.type === 'RP')?.hours || 0,
        moHours: hlrCardDetails?.cpd_types?.find((cpdType) => cpdType?.type === 'MO')?.hours || 0,
        attachments: (memberAttachmentsData?.attachments as AttachmentsArray) || []
      });
    }
  }, [memberAttachmentsData, reset, isEditing, hlrCardDetails?.cpd_types]);

  const hasCPDHoursGreaterThanZero =
    watchAllFields.eaHours > 0 || watchAllFields.rpHours > 0 || watchAllFields.moHours > 0;

  const totalCPDHours = (
    nanToZero(Number(watchAllFields.eaHours)) +
    nanToZero(Number(watchAllFields.rpHours)) +
    nanToZero(Number(watchAllFields.moHours))
  ).toFixed(1);

  const totalCPDHoursGreaterThan500 = Number(totalCPDHours) <= 500.0;

  const handleCheckbox = (event: any) => {
    if (agreeCheckbox) {
      setAgreeCheckbox(false);
    } else {
      setAgreeCheckbox(true);
    }
  };
  const handleReset = () => {
    reset({
      eaHours: 0,
      rpHours: 0,
      moHours: 0,
      hours_spent: 0,
      attachments: []
    });
    setAgreeCheckbox(false);
  };
  const handleDelete = () => {
    deleteHlrActivityMutation();
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <NavLink
          to={'/'}
          style={{ fontSize: '15px', fontWeight: 600, display: 'flex', alignItems: 'center' }}
        >
          <ChevronLeft /> Back
        </NavLink>
        <Button
          variant="text"
          onClick={() => setDialogOpen(true)}
          sx={{ marginLeft: 'auto', minWidth: 'auto', borderRadius: '8px' }}
        >
          Delete
        </Button>
      </Box>
      <Paper sx={{ p: 2, mt: 2 }}>
        <Box ref={scrollRef}>
          <Alerts
            submissionResponse={submissionResponse}
            submissionError={submissionError}
            onDismissSuccessfulSubmission={resetSubmissionMutation}
            deleteResponse={deleteSuccess}
            deleteError={deleteError}
          />
        </Box>
        <HLRContent hlrMemberCardDetails={hlrMemberCardDetails} />
        <Divider sx={{ my: 3 }}></Divider>
        <form>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Activity Details{' '}
                <Typography variant={'caption'} fontWeight={400}>
                  (Required*)
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box>
                <Typography variant="body1" color={'primary'}>
                  Type of CPD*
                </Typography>
                <Typography variant="body2" sx={{ pt: 1, pb: 2 }}>
                  Log hours against any CPD types that apply to your activity. Hours will be
                  totalled in increments of 30 minutes (0.5).
                </Typography>
                <Box sx={{ width: '340px', maxWidth: '100%' }}>
                  <Typography variant="body1" color={'primary'} sx={{ display: 'flex' }}>
                    Educational Activities
                    <Tooltip title="Educational Activities (EA) are activities that expand your general practice knowledge, skills, and attitudes, related to your scope of practice">
                      <InfoOutlinedIcon
                        fontSize="small"
                        sx={{ ml: 1, maxWidth: 20, maxHeight: 20 }}
                      />
                    </Tooltip>
                  </Typography>
                  <Box sx={{ display: 'flex', mt: 2, mb: 3 }}>
                    <Controller
                      name="eaHours"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          {...register('eaHours', {
                            setValueAs: (v: any) => nanToZero(Number.parseFloat(v)),
                            onBlur: () => {
                              roundField('eaHours');
                            }
                          })}
                          sx={{ width: '100%' }}
                          type={'number'}
                          label="Hours"
                          variant="outlined"
                          InputProps={{
                            inputProps: {
                              min: 0,
                              max: 500,
                              step: 0.5
                            }
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Typography variant="body1" color={'primary'} sx={{ display: 'flex' }}>
                    Reviewing Performance
                    <Tooltip title="Reviewing Performance (RP) are activities that require reflection on feedback about your work">
                      <InfoOutlinedIcon
                        fontSize="small"
                        sx={{ ml: 1, maxWidth: 20, maxHeight: 20 }}
                      />
                    </Tooltip>
                  </Typography>
                  <Box sx={{ display: 'flex', mt: 2, mb: 3 }}>
                    <Controller
                      name="rpHours"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          {...register('rpHours', {
                            setValueAs: (v: any) => nanToZero(Number.parseFloat(v)),
                            onBlur: () => {
                              roundField('rpHours');
                            }
                          })}
                          sx={{ width: '100%' }}
                          type={'number'}
                          label="Hours"
                          variant="outlined"
                          InputProps={{
                            inputProps: {
                              min: 0,
                              max: 500,
                              step: 0.5
                            }
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Typography variant="body1" color={'primary'} sx={{ display: 'flex' }}>
                    Measuring Outcomes
                    <Tooltip title="Measuring Outcomes (MO) are activities that use your work data to ensure quality results">
                      <InfoOutlinedIcon
                        fontSize="small"
                        sx={{ ml: 1, maxWidth: 20, maxHeight: 20 }}
                      />
                    </Tooltip>
                  </Typography>
                  <Box sx={{ display: 'flex', mt: 2, mb: 3 }}>
                    <Controller
                      name="moHours"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          {...register('moHours', {
                            setValueAs: (v: any) => nanToZero(Number.parseFloat(v)),
                            onBlur: () => {
                              roundField('moHours');
                            }
                          })}
                          sx={{ width: '100%' }}
                          type={'number'}
                          label="Hours"
                          variant="outlined"
                          InputProps={{
                            inputProps: {
                              min: 0,
                              max: 500,
                              step: 0.5
                            }
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{ display: 'flex' }}>
                    <Typography variant="body1" sx={{ fontWeight: '700' }}>
                      Total:
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        marginLeft: 'auto',
                        flexDirection: 'column',
                        alignItems: 'flex-end'
                      }}
                    >
                      <Typography sx={{ fontWeight: '700' }} color={'secondary'}>
                        {totalCPDHours} CPD hours
                      </Typography>
                      {!totalCPDHoursGreaterThan500 && (
                        <Typography variant="caption" color={'red'}>
                          500 CPD hour limit per activity
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 4, mb: 2 }} />
          <Grid container>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Evidence{' '}
                <Typography variant={'caption'} fontWeight={400}>
                  (Required*)
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography sx={{ mb: 2, color: '#6F6F6F' }} variant="body2">
                If you have evidence of participation in this activity, such as a statement of
                completion, a copy of session materials or your own notes, upload these here for a
                complete record.
              </Typography>
              <Controller
                control={control}
                name={'attachments'}
                rules={{ max: 5, required: true }}
                render={({ formState, fieldState, field: { value, name, onChange } }) => {
                  return (
                    <Attachments
                      onChangeAttachments={onChange}
                      attachments={value}
                      uploadText="EVIDENCE"
                    />
                  );
                }}
              />
              <Divider sx={{ my: 3 }} />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Controller
                  name="agreeToEvidenceProof"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      onClick={handleCheckbox}
                      checked={agreeCheckbox}
                      {...register('agreeToEvidenceProof', { required: true })}
                    />
                  )}
                />
                <Typography sx={{ color: '#6f6f6f' }}>
                  I have provided evidence for all requirements for {hlrCardDetails?.title}.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ my: 3 }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'flex-end'
            }}
          >
            {hlrCardDetails?.hlr_status !== HlrStatus.HlrSubmitted && (
              <Button variant="outlined" sx={{ minWidth: '180px', mr: 2 }} onClick={handleReset}>
                Clear
              </Button>
            )}

            <Button
              variant="contained"
              sx={{ minWidth: '180px' }}
              type="submit"
              disabled={
                !isValid || !isDirty || !hasCPDHoursGreaterThanZero || !totalCPDHoursGreaterThan500
              }
              onClick={submitForm}
            >
              {hlrCardDetails?.hlr_status === HlrStatus.HlrSubmitted ? 'Update' : 'Submit'}
            </Button>
          </Box>
        </form>
      </Paper>
      <Dialog open={dialogOpen}>
        <Box sx={{ p: 2, pb: 0 }}>
          <Typography variant="h3">
            {!deleteError ? 'Are you sure you want to delete?' : 'Unable to delete at this time'}
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {!deleteError
              ? 'All CPD hours and files you may have uploaded will permanently be deleted from this requirement.'
              : 'Activity (' + activeMemberId + ' ' + hlrCardDetails.sortkey + ') is not found.'}
          </Typography>
        </Box>
        <DialogActions sx={{ py: 0, pt: 1, pb: 2 }}>
          <Button
            onClick={handleDialog}
            sx={{ color: '#6F6F6F', minWidth: 'auto', borderRadius: '8px' }}
          >
            Cancel
          </Button>
          <Button onClick={handleDelete} autoFocus sx={{ minWidth: 'auto', borderRadius: '8px' }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export { HLRForm };
