import { useContext } from 'react';
import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { Container } from '@mui/material';
import { ContentPageTitle } from 'src/components/ContentPageTitle/ContentPageTitle';
import { HLRForm } from './HLRForm';
import {
  useGetMemberHlrDetailsQuery,
  HighLevelRequirementDetail,
  useGetMemberHlrSearchQuery,
  HlrActivityTypeDateRangeInput
} from 'src/graphql/generated';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { LoadableBox } from 'src/components/Loadable/LoadableBox';

const HlrPage = () => {
  const { activeMemberId } = useContext(AuthContext) as AuthContextType;
  const currentDate = format(new Date(), 'yyyy-MM-dd');
  const { activitykey } = useParams<{ activitykey: string }>();

  const { data, loading: memberHlrDetails } = useGetMemberHlrDetailsQuery({
    variables: { memberId: activeMemberId, asAtDate: currentDate },
    fetchPolicy: 'cache-and-network'
  });

  const { data: HLRData, loading: memberHlrSearch } = useGetMemberHlrSearchQuery({
    variables: { memberId: activeMemberId, dateAsAt: currentDate },
    fetchPolicy: 'cache-and-network'
  });

  const decodedActivityKey = decodeURIComponent(activitykey);

  const hlrSearchCardDetails = data?.getMemberHlrDetails?.find(
    (currentHlr) =>
      currentHlr?.sortkey === decodedActivityKey ||
      currentHlr?.cpd_id === Number(decodedActivityKey)
  ) as HighLevelRequirementDetail;

  const hlrID = hlrSearchCardDetails?.activity_type_id;

  const hlrMemberCardDetails = HLRData?.getMemberHlrSearch?.items?.find(
    (HLRDetails) => HLRDetails?.activity_type_id === hlrID
  ) as HlrActivityTypeDateRangeInput;

  const loading = memberHlrSearch || memberHlrDetails;

  return (
    <>
      <ContentPageTitle currentPageTitle={hlrSearchCardDetails?.title!} showImpersonate={true} />
      <Container maxWidth="lg">
        <LoadableBox loading={loading}>
          <HLRForm
            hlrMemberCardDetails={hlrMemberCardDetails}
            hlrSearchCardDetails={hlrSearchCardDetails}
          />
        </LoadableBox>
      </Container>
    </>
  );
};
export { HlrPage };
