import React from 'react';
import { ThemeProvider, Box } from '@mui/material';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Document } from 'src/components/Document';
import { ErrorBoundary } from 'react-error-boundary';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { LicenseInfo } from '@mui/x-license-pro';

import { RolesRestricted } from '../RequiredAuth/RolesAuthRoute';
import { ConfiguredOktaSecurity } from 'src/contexts/ConfiguredOktaSecurity';
import { ConfiguredApolloProvider } from 'src/contexts/ConfiguredApolloProvider';
import { AuthContextProvider } from 'src/contexts/AuthContext';
import { BrowseContextProvider } from 'src/contexts/BrowseContext';
import './App.css';
import { MyCPD } from 'src/components/Pages/MyCPD';
import { BrowsePage } from 'src/components/Pages/Browse';
import { HistoryPage } from 'src/components/Pages/History';
import { ActivityPage } from 'src/components/Pages/Activity';
import { PdpPage } from 'src/components/Pages/PDP';
import { HlrPage } from 'src/components/Pages/HLR';
import { BookmarksPage } from 'src/components/Pages/Bookmarks';
import { HelpPage } from 'src/components/Pages/Help';
import { PreferencesPage } from 'src/components/Pages/Preferences';
import { NotificationsPage } from 'src/components/Pages/Notifications';
import { ContactUsPage } from 'src/components/Pages/ContactUs';
import { ResourcesPage } from 'src/components/Pages/Resources';
import { GuidesAndTemplatesPage } from 'src/components/Pages/GuidesAndTemplates';
import { MajorCPDProvidersPage } from 'src/components/Pages/MajorCPDProviders';
import { MissingPage } from '../Error/MissingPage';
import Header from 'src/components/Header/Header';
import Footer from 'src/components/Footer/Footer';
import { theme } from './theme';
import { ErrorBoundaryFallback } from 'src/components/ErrorBoundary/ErrorBoundaryFallback';
import { EditInterests } from '../Pages/EditInterests';
import {
  EditQuickLog as EditQuickLogPage,
  NewQuickLog as NewQuickLogPage,
  EditQuickLogByCpdId as EditQuickLogByCpdIdPage
} from '../Pages/Log';
import { HistoryContextProvider } from '../../contexts/HistoryContext';
import { PageErrorBoundary } from '../ErrorBoundary/PageErrorBoundary';
import { ScrollToTop } from 'src/components/ScrollToTop/ScrollToTop';
import { WhatsNewPopup } from 'src/components/WhatsNewPopup/WhatsNewPopup';

LicenseInfo.setLicenseKey(
  '5dd62ce1390db150e1c422688a8c9b67Tz04MDE4MCxFPTE3MzM2MTIxMzQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <ScrollToTop />
          <ConfiguredOktaSecurity>
            <ConfiguredApolloProvider>
              <AuthContextProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <BrowseContextProvider>
                    <Box
                      sx={{
                        height: '100vh',
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <WhatsNewPopup />
                      <Header />

                      <Box
                        sx={{
                          backgroundColor: '#f8f8f8',
                          flexGrow: 1,
                          pb: 7
                        }}
                      >
                        <Switch>
                          <Route
                            path="/"
                            exact
                            component={() => (
                              <PageErrorBoundary key={'mycpd-boundary'}>
                                <RolesRestricted
                                  roles={['member', 'staff']}
                                  fallbackRoute="/browse"
                                >
                                  <Document title="MyCPD - Home">
                                    <MyCPD />
                                  </Document>
                                </RolesRestricted>
                              </PageErrorBoundary>
                            )}
                          />
                          <Route
                            path="/mycpd"
                            component={() => (
                              <PageErrorBoundary key={'mycpd-boundary'}>
                                <RolesRestricted
                                  roles={['member', 'staff']}
                                  fallbackRoute="/browse"
                                >
                                  <Document title="MyCPD - Home">
                                    <MyCPD />
                                  </Document>
                                </RolesRestricted>
                              </PageErrorBoundary>
                            )}
                          />
                          <Route
                            path="/edit-interests"
                            component={() => (
                              <PageErrorBoundary key={'edit-interests-boundary'}>
                                <RolesRestricted roles={['member', 'staff']}>
                                  <Document title="MyCPD - Edit Interests">
                                    <EditInterests />
                                  </Document>
                                </RolesRestricted>
                              </PageErrorBoundary>
                            )}
                          />
                          <Route
                            path={'/log/cpdid/:cpdId'}
                            component={() => (
                              <PageErrorBoundary key={'log-boundary'}>
                                <RolesRestricted roles={['member', 'staff']}>
                                  <Document title="MyCPD - Edit Quick Log By Id">
                                    <EditQuickLogByCpdIdPage />
                                  </Document>
                                </RolesRestricted>
                              </PageErrorBoundary>
                            )}
                          />
                          <Route
                            path={'/log/:id'}
                            component={() => (
                              <PageErrorBoundary key={'log-boundary'}>
                                <RolesRestricted roles={['member', 'staff']}>
                                  <Document title="MyCPD - Edit Quick Log">
                                    <EditQuickLogPage />
                                  </Document>
                                </RolesRestricted>
                              </PageErrorBoundary>
                            )}
                          />
                          <Route
                            path="/log"
                            component={() => (
                              <PageErrorBoundary key={'log-boundary'}>
                                <RolesRestricted roles={['member', 'staff']}>
                                  <Document title="MyCPD - New Quick Log">
                                    <NewQuickLogPage />
                                  </Document>
                                </RolesRestricted>
                              </PageErrorBoundary>
                            )}
                          />
                          <Route
                            path="/browse"
                            component={() => (
                              <PageErrorBoundary key={'browse-boundary'}>
                                <RolesRestricted roles={['member', 'staff', 'provider']}>
                                  <Document title="MyCPD - Browse">
                                    <BrowsePage />
                                  </Document>
                                </RolesRestricted>
                              </PageErrorBoundary>
                            )}
                          />
                          <Route
                            path="/activity/:id"
                            component={() => (
                              <PageErrorBoundary key={'activity'}>
                                <RolesRestricted roles={['member', 'staff', 'provider']}>
                                  <Document title="MyCPD - Activity">
                                    <ActivityPage />
                                  </Document>
                                </RolesRestricted>
                              </PageErrorBoundary>
                            )}
                          />
                          <Route
                            path="/history"
                            component={() => (
                              <PageErrorBoundary key={'history-boundary'}>
                                <RolesRestricted roles={['member', 'staff']}>
                                  <HistoryContextProvider>
                                    <Document title="MyCPD - History">
                                      <HistoryPage />
                                    </Document>
                                  </HistoryContextProvider>
                                </RolesRestricted>
                              </PageErrorBoundary>
                            )}
                          />
                          <Route
                            path="/pdp/:year"
                            component={() => (
                              <PageErrorBoundary key={'pdp-boundary'}>
                                <RolesRestricted roles={['member', 'staff']}>
                                  <Document title="MyCPD - PDP">
                                    <PdpPage />
                                  </Document>
                                </RolesRestricted>
                              </PageErrorBoundary>
                            )}
                          />

                          <Route
                            path="/hlr/:activitykey"
                            component={() => (
                              <PageErrorBoundary key={'pdp-boundary'}>
                                <RolesRestricted roles={['member', 'staff']}>
                                  <Document title="MyCPD - High Level Requirements">
                                    <HlrPage />
                                  </Document>
                                </RolesRestricted>
                              </PageErrorBoundary>
                            )}
                          />
                          <Route
                            path="/bookmarks"
                            component={() => (
                              <PageErrorBoundary key={'bookmarks-boundary'}>
                                <RolesRestricted roles={['member', 'staff']}>
                                  <Document title="MyCPD - Bookmarks">
                                    <BookmarksPage />
                                  </Document>
                                </RolesRestricted>
                              </PageErrorBoundary>
                            )}
                          />
                          <Route
                            path="/help"
                            component={() => (
                              <PageErrorBoundary key={'help-boundary'}>
                                <RolesRestricted roles={['member', 'staff']}>
                                  <Document title="MyCPD - Help">
                                    <HelpPage />
                                  </Document>
                                </RolesRestricted>
                              </PageErrorBoundary>
                            )}
                          />
                          <Route
                            path="/preferences"
                            component={() => (
                              <PageErrorBoundary key={'preferences-boundary'}>
                                <RolesRestricted roles={['member', 'staff']}>
                                  <Document title="MyCPD - Preferences">
                                    <PreferencesPage />
                                  </Document>
                                </RolesRestricted>
                              </PageErrorBoundary>
                            )}
                          />
                          <Route
                            path="/notifications"
                            component={() => (
                              <PageErrorBoundary key={'notifications-boundary'}>
                                <RolesRestricted roles={['member', 'staff']}>
                                  <Document title="MyCPD - Notifications">
                                    <NotificationsPage />
                                  </Document>
                                </RolesRestricted>
                              </PageErrorBoundary>
                            )}
                          />
                          <Route
                            path="/contact-us"
                            component={() => (
                              <PageErrorBoundary key={'contact-us-boundary'}>
                                <RolesRestricted roles={['member', 'staff', 'provider']}>
                                  <Document title="MyCPD - Contact Us">
                                    <ContactUsPage />
                                  </Document>
                                </RolesRestricted>
                              </PageErrorBoundary>
                            )}
                          />
                          <Route
                            path="/resources"
                            component={() => (
                              <PageErrorBoundary key={'resources-boundary'}>
                                <RolesRestricted roles={['member', 'staff']}>
                                  <Document title="MyCPD - Resources">
                                    <ResourcesPage />
                                  </Document>
                                </RolesRestricted>
                              </PageErrorBoundary>
                            )}
                          />
                          <Route
                            path="/guides-templates"
                            component={() => (
                              <PageErrorBoundary key={'guides-templates-boundary'}>
                                <RolesRestricted roles={['member', 'staff']}>
                                  <Document title="MyCPD - Guides and Templates">
                                    <GuidesAndTemplatesPage />
                                  </Document>
                                </RolesRestricted>
                              </PageErrorBoundary>
                            )}
                          />
                          <Route
                            path="/major-cpd-providers"
                            component={() => (
                              <PageErrorBoundary key={'major-cpd-providers'}>
                                <RolesRestricted roles={['member', 'staff', 'provider']}>
                                  <Document title="MyCPD - Major CPD Providers">
                                    <MajorCPDProvidersPage />
                                  </Document>
                                </RolesRestricted>
                              </PageErrorBoundary>
                            )}
                          />
                          {/* Note: MissingPage as last Route so it is default if nothing else matches */}
                          <Route
                            path="*"
                            component={() => (
                              <PageErrorBoundary key={'missing-page-boundary'}>
                                <Document title="MyCPD - Page not found">
                                  <MissingPage />
                                </Document>
                              </PageErrorBoundary>
                            )}
                          />
                        </Switch>
                      </Box>
                      <Footer />
                    </Box>
                  </BrowseContextProvider>
                </LocalizationProvider>
              </AuthContextProvider>
            </ConfiguredApolloProvider>
          </ConfiguredOktaSecurity>
        </BrowserRouter>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
