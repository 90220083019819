import React, { useRef, useCallback } from 'react';
import {
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Box,
  Link,
  Button,
  useTheme,
  Alert
} from '@mui/material';
import { NavLink, useParams, useLocation } from 'react-router-dom';
import {
  AccessTime,
  CalendarToday,
  RoomOutlined,
  LocalPhoneOutlined,
  EmailOutlined,
  Person,
  DesktopMacOutlined,
  Groups,
  Videocam,
  Subject,
  ChevronLeft,
  Apartment,
  PeopleAlt
} from '@mui/icons-material';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';

import { BookmarkButton } from '../Bookmarks/BookmarkButton';
import { ContentPageTitle } from 'src/components/ContentPageTitle/ContentPageTitle';
import {
  useGetSearchActivityByIdQuery,
  CpdActivityContent,
  CpdActivityCurriculumContextualUnit,
  CpdActivitySession,
  CpdActivityRequirement
} from 'src/graphql/generated';
import { useErrorHandler } from 'src/hooks/useErrorHandler';
import { SessionDetailsList } from './SessionDetailsList';
import {
  DELIVERY_MODE_BLENDED,
  DELIVERY_MODE_ELEARNING,
  DELIVERY_MODE_FACE_TOFACE,
  DELIVERY_MODE_VIRTUAL
} from '../Browse/constants';
import { getUniqueSessionStates } from 'src/components/Pages/Browse/getUniqueSessionStates';
import { TagWithList } from './TagWithList';
import { MissingPage } from 'src/components/Error/MissingPage';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

type ActivityBackState = {
  backTo?: string;
};

const ActivityPage = () => {
  const scrollRef = useRef<HTMLElement>(null);
  const location = useLocation();
  const { backTo } = (location.state || {}) as ActivityBackState;
  const backLocation = backTo && backTo.length > 0 ? backTo : '/browse';
  const theme = useTheme();
  const { id } = useParams<{ id: string }>();
  const handleError = useErrorHandler();
  const sessionSettingsUpdated = useLocation<{ onSessionSettingsUpdated: Boolean }>();
  const { onSessionSettingsUpdated = false } = sessionSettingsUpdated?.state || {};
  const scrollToTop = useCallback(() => {
    setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }, 10);
  }, []);
  if (onSessionSettingsUpdated) {
    scrollToTop();
  }
  const { data, loading } = useGetSearchActivityByIdQuery({
    variables: { activityid: id ?? '' },
    onError: handleError
  });

  const activityList = _.first(data?.searchActivity?.items || []);
  const activity = (activityList && activityList.activity) as CpdActivityContent;
  const hasActivity = activity?.id === id;

  if (loading) {
    // TODO: move loading inside of the page <Container>
    // When loading is outside of <Container> component the page jumps to full width when loading
    return (
      <>
        <ContentPageTitle currentPageTitle="Loading..." />
        <Container maxWidth="lg">
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 2 }}
          >
            <NavLink
              to={backLocation}
              style={{ fontSize: '15px', fontWeight: 600, display: 'flex', alignItems: 'center' }}
            >
              <ChevronLeft /> Back
            </NavLink>
          </Box>
        </Container>
      </>
    );
  }

  const requirementsListTemp = (activity?.requirements ?? []) as CpdActivityRequirement[];
  var order = ['Education', 'Reviewing', 'Measuring', 'CPR'];
  const requirementsList = _.sortBy(requirementsListTemp, function (requirement) {
    const requirementFirstWord = (requirement?.name ?? '').split(' ')[0];
    return _.indexOf(order, requirementFirstWord);
  });

  const learningOutcomesList = (activity?.learningOutcomes ?? []) as string[];
  const programLevelList = (activity?.programLevels ?? []) as string[];

  const sessionList = (activity?.sessions ?? []) as CpdActivitySession[];
  const uniqueSessionStates = getUniqueSessionStates(sessionList);
  const statesString = uniqueSessionStates.join(', ');

  if (!hasActivity) {
    return <MissingPage />;
  }

  const startDateTime = format(
    parseISO(`${activity.sessionSeriesStartDate}`),
    'eee do MMM yyyy - p'
  );
  const startDate = format(parseISO(`${activity.sessionSeriesStartDate}`), 'eee do MMM yyyy');
  const endDate = format(parseISO(`${activity.sessionSeriesEndDate}`), 'eee do MMM yyyy');
  const showStateFilter = uniqueSessionStates.length > 1 ? true : false;

  const grantsList: string[] = [];
  if (activity.hasAnaestheticGrant) {
    grantsList.push('Anaesthetic Grant');
  }
  if (activity.hasEmergencyMedicineGrant) {
    grantsList.push('Emergency Medicine Grant');
  }
  if (activity.hasObstetricGrant) {
    grantsList.push('Obstetric Grant');
  }
  if (activity.hasSurgeryGrant) {
    grantsList.push('Surgery Grant');
  }
  const hasGrantsTag = grantsList.length > 0;

  const hasSpecificRequirements =
    Array.isArray(activity?.specificRequirements) && activity?.specificRequirements.length > 0;

  const domainsList = (activity?.gpDomains ?? []) as string[];
  let sortedDomainsList = _.sortBy(domainsList, (a) => {
    const aSubstring = a.substring(0, 2); // use the Domain code to sort
    return aSubstring;
  });
  // remove the Domain code from the string because it was too hard from the backend
  sortedDomainsList = sortedDomainsList.map(
    (domain) => _.last(domain.split('.'))?.trim() as string
  );
  return (
    <>
      <ContentPageTitle currentPageTitle={activity.title!} />

      <Container maxWidth="lg">
        {onSessionSettingsUpdated && (
          <Box ref={scrollRef}>
            <Alert
              sx={{ my: 2 }}
              icon={<CheckCircleOutlineIcon fontSize="inherit" />}
              severity="success"
            >
              <Typography fontWeight={500} pb={1}>
                Session event reminder successfully saved.
              </Typography>
              <Typography>
                You previously had Session Event Reminders disabled. These have been re-enabled for
                your convenience.
              </Typography>
              <Typography>
                To customise your preferences, go to{' '}
                <NavLink
                  to={{
                    pathname: `/notifications`,
                    state: { openSettings: true }
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ textDecoration: 'underline', fontSize: '1rem' }}
                  >
                    Notification settings.
                  </Typography>
                </NavLink>
              </Typography>
            </Alert>
          </Box>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 2 }}>
          <NavLink
            to={backLocation}
            style={{ fontSize: '15px', fontWeight: 600, display: 'flex', alignItems: 'center' }}
          >
            <ChevronLeft /> Back
          </NavLink>
          <BookmarkButton
            hasDeleteConfirmation={false}
            bookmarkButtonType="detail"
            activity={activity}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <Paper sx={{ p: 2 }}>
              <Box
                sx={{
                  backgroundColor: '#fafafa',
                  p: 2,
                  borderRadius: '5px'
                }}
              >
                <Box sx={{ display: 'flex', mb: 1 }}>
                  <AccessTime
                    color="secondary"
                    fontSize="small"
                    sx={{
                      mr: 1
                    }}
                  />
                  <Typography color="secondary" variant="body1" sx={{ fontWeight: 600 }}>
                    {activity.hours} CPD Hours
                  </Typography>
                </Box>

                {requirementsList.map((requirement: CpdActivityRequirement) => {
                  const hasHours = requirement.hours > 0;
                  return (
                    <Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                          {requirement.name}
                        </Typography>
                        {hasHours && (
                          <Typography variant="body2">{requirement.hours} CPD Hours</Typography>
                        )}
                      </Box>
                      <Divider />
                    </Box>
                  );
                })}
              </Box>
              <Box sx={{ p: 2 }}>
                <Box
                  sx={{
                    py: 1,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <CalendarToday
                    sx={{
                      mr: 1,
                      ...theme.typography.h5
                    }}
                  />

                  <Typography variant="body2">
                    {startDate !== endDate ? (
                      <>
                        {startDate} - {endDate}
                      </>
                    ) : (
                      startDateTime
                    )}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    py: 1,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <RoomOutlined
                    sx={{
                      mr: 1,
                      ...theme.typography.h5
                    }}
                  />

                  <Typography variant="body2">{statesString}</Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    py: 1,
                    display: 'flex'
                  }}
                >
                  {(() => {
                    switch (activity?.deliveryMode) {
                      case DELIVERY_MODE_BLENDED:
                        return (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <Groups sx={{ mr: 1, ...theme.typography.h5 }} />
                            <Typography variant="body2">Blended </Typography>
                          </Box>
                        );
                      case DELIVERY_MODE_ELEARNING:
                        return (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <DesktopMacOutlined sx={{ mr: 1, ...theme.typography.h5 }} />
                            <Typography variant="body2">e-Learning </Typography>
                          </Box>
                        );
                      case DELIVERY_MODE_FACE_TOFACE:
                        return (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <Person sx={{ mr: 1, ...theme.typography.h5 }} />
                            <Typography variant="body2">Face-to-face</Typography>
                          </Box>
                        );
                      case DELIVERY_MODE_VIRTUAL:
                        return (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <Videocam sx={{ mr: 1, ...theme.typography.h5 }} />
                            <Typography variant="body2">Virtual</Typography>
                          </Box>
                        );
                      default:
                        return (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <Subject sx={{ mr: 1, ...theme.typography.h5 }} />
                            <Typography variant="body2">Other</Typography>
                          </Box>
                        );
                    }
                  })()}
                </Box>
                <Divider />
                <Box
                  sx={{
                    py: 1,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Apartment
                    sx={{
                      mr: 1,
                      ...theme.typography.h5
                    }}
                  />

                  <Typography variant="body2">{activity.providerName}</Typography>
                </Box>
                <Divider />
              </Box>
              <Button
                color="primary"
                variant="contained"
                size="small"
                sx={{ my: 2, width: '100%', padding: '10px 24px', lineHeight: '20px' }}
                component="a"
                href="#sessions-placeholder"
              >
                View available sessions
              </Button>

              <Box
                sx={{
                  p: 2
                }}
              >
                <Typography variant="h3" sx={{ ...theme.typography.body2, fontWeight: 600, mb: 1 }}>
                  For enrolment information
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    py: 1
                  }}
                >
                  <PeopleAlt
                    sx={{
                      mr: 1,
                      ...theme.typography.h5
                    }}
                  />
                  <Typography variant="body2">
                    {activity.contact?.firstName} {activity.contact?.lastName}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    py: 1
                  }}
                >
                  <LocalPhoneOutlined
                    sx={{
                      mr: 1,
                      ...theme.typography.h5
                    }}
                  />
                  <Typography variant="body2">
                    <Link href={`tel: ${activity.contact?.phone}`}>{activity.contact?.phone}</Link>
                  </Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    py: 1
                  }}
                >
                  <EmailOutlined
                    sx={{
                      mr: 1,
                      ...theme.typography.h5
                    }}
                  />
                  <Link
                    href={`mailto: ${activity.contact?.email}`}
                    sx={{ ...theme.typography.body2 }}
                  >
                    {activity.contact?.email}
                  </Link>
                </Box>

                {(hasGrantsTag || hasSpecificRequirements || activity?.ruralCpdYesNo) && (
                  <Box
                    sx={{
                      px: 2,
                      py: 2
                    }}
                  >
                    {hasGrantsTag && (
                      <Box
                        sx={{
                          py: 1
                        }}
                      >
                        <TagWithList title="Grants eligible" stringList={grantsList} />
                      </Box>
                    )}

                    {hasSpecificRequirements && (
                      <Box
                        sx={{
                          py: 1
                        }}
                      >
                        <TagWithList
                          title="Specific requirements"
                          stringList={(activity?.specificRequirements as string[]) ?? []}
                        />
                      </Box>
                    )}

                    {activity?.ruralCpdYesNo === 'Yes' && (
                      <Box
                        sx={{
                          py: 1
                        }}
                      >
                        <TagWithList title="Rural" />
                      </Box>
                    )}
                  </Box>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    ...theme.typography.body2,
                    color: '#6f6f6f'
                  }}
                >
                  Activity ID: {activity.id}
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: { xs: 2, md: 3 }, mb: 2 }}>
              <Typography variant="h3" component="h2" sx={{ fontWeight: 600, mb: 1 }}>
                Course Overview
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {activity.description}{' '}
              </Typography>
              {activity.relevance && (
                <Box>
                  <Typography variant="h3" component="h2" sx={{ fontWeight: 600, mb: 1 }}>
                    Relevance to General Practice
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {activity.relevance}
                  </Typography>
                </Box>
              )}
              <Typography variant="h3" component="h2" sx={{ fontWeight: 600, mb: 1 }}>
                Learning Outcomes
              </Typography>
              {learningOutcomesList && (
                <Box sx={{ mb: 2 }}>
                  <ol>
                    {learningOutcomesList.map((outcome, index) => {
                      return (
                        <li key={index}>
                          <Typography>{outcome}</Typography>
                        </li>
                      );
                    })}
                  </ol>
                </Box>
              )}
              {sortedDomainsList.length > 0 && (
                <Typography variant="h3" component="h2" sx={{ fontWeight: 600, mb: 1 }}>
                  Core Units
                </Typography>
              )}

              <Box sx={{ mb: 2 }}>
                <ul>
                  {sortedDomainsList.map((domain, index) => {
                    return (
                      <li key={index}>
                        <Typography variant="body1">{domain}</Typography>
                      </li>
                    );
                  })}
                </ul>
              </Box>
              {activity.curriculumContextualUnits!.length > 0 && (
                <Typography variant="h3" component="h2" sx={{ fontWeight: 600, mb: 1 }}>
                  Contextual Units
                </Typography>
              )}

              <Box sx={{ mb: 2 }}>
                <ul>
                  {activity?.curriculumContextualUnits?.map(
                    (contextualUnit: CpdActivityCurriculumContextualUnit | null, index) => {
                      return (
                        <li key={index}>
                          <Typography variant="body1">{contextualUnit?.name}</Typography>
                        </li>
                      );
                    }
                  )}
                </ul>
              </Box>

              {programLevelList.length > 0 && (
                <>
                  <Typography variant="h3" component="h2" sx={{ fontWeight: 600, mb: 1 }}>
                    Program Level Requirements
                  </Typography>

                  <Box sx={{ mb: 2 }}>
                    <ul>
                      {programLevelList.map((programLevel, index) => {
                        return (
                          <li key={index}>
                            <Typography>{programLevel}</Typography>
                          </li>
                        );
                      })}
                    </ul>
                  </Box>
                </>
              )}
            </Paper>

            <SessionDetailsList
              activity={activity}
              sessionsList={(activity?.sessions ?? []) as CpdActivitySession[]}
              showStateFilter={showStateFilter}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export { ActivityPage };
