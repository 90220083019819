import React from 'react';
import { Box, Divider } from '@mui/material';
import { AddRequirements } from './AddRequirements';

const HighLevelRequirements = () => {
  return (
    <Box sx={{ mt: 3, width: '100%' }}>
      <Divider sx={{ mb: 3 }} />
      <AddRequirements />
    </Box>
  );
};
export { HighLevelRequirements };
