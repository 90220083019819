import { Box, Typography, Link } from '@mui/material';
import { HlrActivityTypeDateRangeInput } from 'src/graphql/generated';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import { format, parseISO } from 'date-fns';

type hlrDetailsKeyProps = {
  hlrMemberCardDetails: HlrActivityTypeDateRangeInput;
};
const HLRContent = ({ hlrMemberCardDetails }: hlrDetailsKeyProps) => {
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h4">{hlrMemberCardDetails?.activity_type_hlr_heading}</Typography>
        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <HomeWorkIcon sx={{ fontSize: '14px', mr: 1.5 }} />
          {hlrMemberCardDetails?.activity_type_hlr_specialist_college}
        </Typography>
        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
          <CalendarTodayIcon sx={{ fontSize: '14px', mr: 1.5 }} />
          Due{' '}
          {hlrMemberCardDetails && format(parseISO(hlrMemberCardDetails?.end_date!), 'MMM yyyy')}
        </Typography>

        {hlrMemberCardDetails?.activity_type_hlr_specialist_college_phone && (
          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
            <LocalPhoneOutlinedIcon
              sx={{
                fontSize: '14px',
                mr: 1.5
              }}
            />
            <Link href={`tel: ${hlrMemberCardDetails?.activity_type_hlr_specialist_college_phone}`}>
              {hlrMemberCardDetails?.activity_type_hlr_specialist_college_phone}
            </Link>
          </Typography>
        )}
        {hlrMemberCardDetails?.activity_type_hlr_specialist_college_website && (
          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
            <InsertLinkOutlinedIcon sx={{ fontSize: '14px', mr: 1.5 }} />
            <Link
              target="_blank"
              rel="noreferrer"
              href={`${hlrMemberCardDetails?.activity_type_hlr_specialist_college_website}`}
            >
              {hlrMemberCardDetails?.activity_type_hlr_specialist_college_website}
            </Link>
          </Typography>
        )}
        <Box
          sx={{
            table: {
              border: 'none',
              textAlign: 'left',
              borderCollapse: 'collapse',
              width: '100% !important'
            },
            thead: {
              backgroundColor: '#D2DCE1',
              th: {
                border: 'none',
                py: 1,
                px: 2
              }
            },
            tbody: {
              border: 'none',
              tr: {
                border: 'none',
                '&:nth-child(odd)': { backgroundColor: '#F8F8F8' }
              },
              td: {
                border: 'none',
                py: 1,
                px: 2,
                verticalAlign: 'top'
              }
            }
          }}
          dangerouslySetInnerHTML={{
            __html: hlrMemberCardDetails?.activity_type_hlr_content!
          }}
        ></Box>
      </Box>
    </>
  );
};
export { HLRContent };
