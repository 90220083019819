import React, { useEffect, useMemo, useRef, useState, useContext } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import {
  DataGridPro,
  GridFilterModel,
  GridRowId,
  GridSortModel,
  gridVisibleRowCountSelector,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { theme } from 'src/components/App/theme';

import { AuthContext, AuthContextType } from 'src/contexts/AuthContext';
import { getColumns, getRow } from './grid-definition';
import { MemberActivity, PageViewport } from './types';
import { ActivityDetails } from './ActivityDetails';
import { NoResults } from './NoResults';
import { getFilterModel } from './filter-model';
import { GridPagination } from './GridPagination';
import { NoExistingRecords } from './NoExistingRecords';

import './custom-css.css';
import { Loading } from './Loading';

type ActivityTableProps = {
  items: MemberActivity[] | undefined | null;
  searchKeyword?: string;
  cpdTypes?: string[];
  cycle?: number;
  onViewPortChanged: ({ pageNumber, visibleRows }: PageViewport) => void;
  selfRecordedOnly: boolean;
  loading: boolean;
};

export const ActivityTable = ({
  cpdTypes,
  items,
  searchKeyword,
  cycle,
  onViewPortChanged,
  selfRecordedOnly,
  loading
}: ActivityTableProps) => {
  const apiRef = useGridApiRef();
  const scrollRef = useRef<any>(null);
  const [expandedRows, setExpandedRows] = useState<GridRowId[]>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ sort: 'desc', field: 'date' }]);
  const filterModel = useMemo<GridFilterModel>(() => {
    return getFilterModel({ cpdTypes, searchKeyword, cycle, selfRecordedOnly });
  }, [cpdTypes, cycle, searchKeyword, selfRecordedOnly]);

  const [visibleRowCount, setVisibleRowCount] = React.useState<number>(items?.length || 0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const pageSize = mobile ? 5 : 10;

  const { cpdRole } = useContext(AuthContext) as AuthContextType;
  const isBackdateOpenForCycle = useMemo(() => {
    let hasDatePermission = false;
    const currentDate = new Date();
    if (cpdRole === 'staff') {
      hasDatePermission = true; // staff can backdate all year
    } else if (cpdRole === 'member') {
      const month = currentDate.getMonth();
      hasDatePermission = 0 <= month && month <= 2; // member can only backdate first 3 months
    }
    const isAllowedCycle = (cycle || 0) >= 2024; // opens cycle 2024 and onwards

    const isBackdateOpen = hasDatePermission && isAllowedCycle;
    return isBackdateOpen;
  }, [cpdRole, cycle]);

  const columns = useMemo(() => {
    return getColumns(mobile, isBackdateOpenForCycle);
  }, [mobile, isBackdateOpenForCycle]);

  const rows = useMemo(() => {
    if (!items || loading) {
      return [];
    }
    return items.map((item) => getRow(item));
  }, [items, loading]);

  const listEmpty = rows.length === 0 || visibleRowCount === 0;

  React.useEffect(() => {
    apiRef.current.subscribeEvent('stateChange', () => {
      const count = gridVisibleRowCountSelector(apiRef);
      setVisibleRowCount(count);
    });
  }, [apiRef]);

  const onChangePage = (pageNumber: number) => {
    if (scrollRef.current) {
      setTimeout(() => {
        //todo: this timeout is for row render updates to finish then scroll the data grid into view, find a better way to do this
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    onViewPortChanged({
      pageNumber: currentPage,
      visibleRows: visibleRowCount,
      pageSize
    });
  }, [currentPage, onViewPortChanged, pageSize, visibleRowCount]);

  return (
    <Box>
      <Box sx={{ height: listEmpty ? 600 : null }}>
        <DataGridPro
          apiRef={apiRef}
          ref={scrollRef}
          getRowClassName={(row) => (expandedRows.includes(row.id) ? '' : 'sample-row-class')}
          disableColumnFilter={true}
          sx={{
            borderWidth: 0,
            '& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator': {
              display: 'none'
            },
            '& .MuiDataGrid-footerContainer': {
              justifyContent: 'center',
              pt: 3
            },
            '& .MuiPagination-ul': {
              justifyContent: 'center'
            },
            '& .MuiDataGrid-cell': {
              p: 2
            },
            '& .MuiDataGrid-columnHeader': {
              background:
                'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C2C9D1;'
            },
            '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
              outline: 'none'
            },
            '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
              outline: 'none'
            },
            '&.MuiDataGrid-root .MuiDataGrid-virtualScroller': {
              //hiding this element as it prevents NoRowsOverlay to be clickable, if there are any issues with NoRowsOverlay remove this and check if it fixes it
              zIndex: listEmpty ? -1 : 1
            },
            '& div div div div >.MuiDataGrid-cell': {
              borderBottom: 'none'
            }
          }}
          filterModel={filterModel}
          disableColumnResize
          rows={rows}
          pagination
          loading={loading}
          pageSize={pageSize}
          getRowHeight={() => 'auto'} // set this to auto because otherwise there is a flash or double render
          {...(mobile ? { headerHeight: 0 } : {})}
          autoHeight={!listEmpty}
          columns={columns}
          onPageChange={onChangePage}
          sortModel={sortModel}
          sortingOrder={['asc', 'desc']} // note this only works when you only sort by one column (date)
          onSortModelChange={setSortModel}
          disableColumnMenu={true}
          disableSelectionOnClick
          detailPanelExpandedRowIds={expandedRows}
          onDetailPanelExpandedRowIdsChange={(ids) => {
            setExpandedRows(ids);
          }}
          experimentalFeatures={{ newEditingApi: true }}
          getDetailPanelContent={({ row }) => {
            return <ActivityDetails activity={row?.activity}></ActivityDetails>;
          }}
          getDetailPanelHeight={() => 'auto'}
          components={{
            Pagination: () => {
              return (
                <Box>
                  <GridPagination apiRef={apiRef} />
                </Box>
              );
            },
            MoreActionsIcon: () => <MoreHorizIcon />,
            DetailPanelExpandIcon: ExpandMoreIcon,
            DetailPanelCollapseIcon: ExpandLessIcon,
            NoRowsOverlay: NoExistingRecords,
            NoResultsOverlay: NoResults,
            LoadingOverlay: Loading
          }}
        />
      </Box>
    </Box>
  );
};
