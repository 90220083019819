import { useState } from 'react';
import { format } from 'date-fns';

const FALLBACK_CYCLE = '2024';
const VALID_CYCLES = ['2024', '2023'];

const getInitialActiveCycle = (cycles: string[]) => {
  const currentYear = format(new Date(), 'yyyy');
  const cycle = cycles.find((s) => s === currentYear);
  return cycle || FALLBACK_CYCLE;
};

export const useCycles = (): {
  cyclesList: string[];
  activeCycle: string;
  setActiveCycle: (newCycle: string) => void;
} => {
  const [activeCycle, setActiveCycle] = useState(getInitialActiveCycle(VALID_CYCLES));

  return { cyclesList: VALID_CYCLES, activeCycle, setActiveCycle };
};
